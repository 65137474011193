body {
    overflow-y: auto !important;
}

.input-field .worng {
    color: #F44336 !important;
}

.btn, .btn-flat, .btn-large, .btn-small {
    text-transform: capitalize;
}

button[type=submit] {
    margin-right: 1rem;
}

h6.detalle {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: .5rem;
    color: #6b6f82;
}

div.detalle {
    margin-bottom: 1rem;
}

.pointer {
    cursor: pointer !important;
}

.btn-padding {
    padding: 0 1rem !important;
}

table.dataTable {
    width: 100% !important;
}

table.dataTable thead .sorting {
    background-image: url(./assets/img/sort_both_custom.png) !important;
}

.jc-center {
    justify-content: center;
}

.sidenav li a.active {
    background: #0288d1 !important;
    box-shadow: none !important;
}

.brand-sidebar .brand-logo img {
    height: 64px !important;
}

.brand-sidebar .brand-logo {
    padding: 0 12px 22px 22px !important;
}

.modal {
    background-color: #fff;
}

.modal.modal-fixed-header {
    padding: 0;
    height: 70%;
}

.modal.modal-fixed-header .modal-header {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    border-radius: 0 0 2px 2px;
    padding: 4px 24px;
    height: 56px;
    width: 100%;
}

.modal.modal-fixed-header .modal-content {
    position: absolute;
    top:56px;
    height: calc(100% - 112px);
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 0 24px;
}

.modal.modal-fixed-header .modal-footer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    border-radius: 0 0 2px 2px;
    padding: 4px 24px;
    height: 56px;
    width: 100%;
}

.ng-select .ng-select-container .ng-value-container { 
    padding: 0 !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
    margin: 0 !important;
    color: #000 !important;
}

.ng-select .ng-spinner-loader {
    top: 1.75em;
}

.ng-select-value .ng-spinner-loader {
    top: 6px !important;
}

.ng-select .ng-select-container .ng-value-container {
    border-top: none !important;
}

.ng-select .ng-select-container {
    min-height: 3rem !important;
}

.ng-select.ng-select-focused .ng-select-container::after {
    border-color: #26a69a !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    color: #000 !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input {
    bottom: 0 !important;
}

.no-form .ng-select {
    bottom: 0 !important;
}

.label-select {
    position: relative !important;
    left: 0 !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    height: 3rem !important;
    line-height: 3rem !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    color: #26a69a !important;
}

ng-select.invalid .ng-select-container {
    border-color: #F44336;
    -webkit-box-shadow: 0 2px 0 0 #F44336;
    box-shadow: 0 2px 0 0 #F44336;
}

.ng-select.invalid .ng-select-container:after {
    border-bottom: none !important;
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    color: #000 !important;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow {
    display: none !important;
}

.ng-select.ng-select-disabled .ng-select-container:after {
    background-image: none !important;
    border-bottom: none !important;
}

.ng-select.disabled .ng-select-container .ng-value-container .ng-value {
    color: #000 !important;
}

.ng-select.disabled .ng-select-container:after {
    background-image: none !important;
    border-bottom: none!important;
}

.ng-select.disabled .ng-arrow-wrapper .ng-arrow {
    display: none !important;
 }
 
 .ng-select.disabled ng-dropdown-panel {
   display: none !important;
 }
 
 .ng-select.disabled .ng-clear-wrapper {
  display: none !important;
 }

 .ng-select .ng-arrow-wrapper {
    bottom: .75em !important;
}

.min-width-120 {
    min-width: 120px;
}

.min-width-90 {
    min-width: 90px;
}

.min-width-60 {
    min-width: 60px;
}

.min-width-30 {
    min-width: 30px;
}

ul.stepper app-step:not(:last-of-type) .step::after {
    content: '';
    position: absolute;
    top: 52px;
    left: 13.5px;
    width: 1px;
    height: 40%;
    height: calc(100% - 52px);
    background-color: rgba(0,0,0,.1);
    transition: height .4s cubic-bezier(.4,0,.2,1);
}

table.dataTable, table.dataTable th, table.dataTable td {
    box-sizing: border-box !important;
}

table.dataTable [type="checkbox"]+span:not(.lever) {
    height: 20px !important;
    line-height: 20px !important;
}

@media (min-width: 576px) {
.form-inline .form-group, .form-inline label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.form-inline .form-check-input {
    position: relative !important;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
}
.form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    padding-left: 0;
}
.form-inline label {
    justify-content: center;
}
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
    opacity: 1 !important;
    pointer-events: all !important;
}

.form-check-label {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.form-inline .form-check {
    width: 100%;
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.btn-sidenav-toggle {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
    margin: 0 !important;
    background-color: #1976d2 !important;
}

.btn-sidenav-toggle i {
    line-height: 40px !important;
}

@media only screen and (max-width: 600px) {
.btn-sidenav-toggle {
    position: relative;
    top: 6px;
    left: 6px;
}
}

@media only screen and (max-width: 992px) {
.btn-sidenav-toggle {
    position: relative;
    top: 10px;
    left: 10px;
}
nav .brand-logo {
    padding-left: 0 !important;
}
.dataTables_wrapper {
    margin-bottom: 20px;
}
}

.pl-260 {
    padding-left: 260px;
}

table.dataTable.grilla {
    border: none !important;
}

table.dataTable.grilla tbody tr {
    background-color: transparent !important;
    border: none !important;
}

table.dataTable.grilla thead, .pedidos-table .dataTables_info {
    display: none !important;
}

.pedidos-table .dataTables_paginate {
    float: none !important;
    text-align: center !important;
}

.card.card-pedido {
    overflow: hidden;
}

.card-badge.oferta {
    line-height: 2em;
    position: absolute;
    top: .8rem;
    right: -10rem;
    display: block;
    width: 24rem;
    height: 2em;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    text-indent: .2em;
    background: #d50000;
    background: -webkit-linear-gradient(45deg,#d50000 0,#ff6d00 100%);
    background: linear-gradient(45deg,#d50000 0,#ff6d00 100%);
}

.card-badge.oferta a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    text-decoration: none;
    color: #dedede;
}

.card-badge.oferta a:before, .card-badge.oferta a:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    content: '';
}

.card-badge.oferta a:after {
    top: auto !important;
    bottom: 0;
    border-bottom-width: 0;
}

.img-container {
    height: 160px;
    width: 100%;
    background-size:cover;
}

.counter-item {
    height: 24px !important;
    width: 48px !important;
    text-align: center;
    margin: 8 0 0 0 !important;
}

.counter-btn {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
    background: #3f51b5 !important;
    box-shadow: 0 0 8px 0 #3f51b5 !important;
    margin-top: 8px;
}

.shopping-cart-btn {
    background: #009688 !important;
    box-shadow: 0 0 8px 0 #009688 !important;
}

.counter-btn i {
    line-height: 24px !important;
}

.card-pedido:hover .btn-vista-rapida {
    display: block;
}

.btn-vista-rapida {
    display: none;
    color: #777676;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -21px 0 0 -53px;
    background: rgba(255,255,255,0.82);
    text-align: center;
    -moz-box-shadow: rgba(0,0,0,0.16) 0 2px 8px;
    -webkit-box-shadow: rgb(0 0 0 / 16%) 0 2px 8px;
    box-shadow: rgb(0 0 0 / 16%) 0 2px 8px;
    border: none;
    border-radius: 2px;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}

.btn-vista-rapida:hover {
    color: #000;
    background: white;
    cursor: pointer;
}

.btn-cerrar {
    position: absolute;
    top: 2px;
    right: 4px;
}

.img-container {
    height: calc(100vw * 0.79);
}

.img-container img {
    max-height: calc(100vw * 0.79);
}

.indicators-images {
    min-height: 69px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 15px;
    justify-content: center;
}

.indicators-images .item {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    height: 72px;
    width: 72px;
    margin-right: 8px;
    padding: 2px;
    border-radius: 3px;
    position: relative;
    border: 2px solid rgba(0,0,0,.25);
    cursor: pointer;
}

.indicators-images .item:hover,  .indicators-images .item.selected{
    border-color: #3f51b5 !important;
}

.col.img {
    padding: 0 .75rem !important;
}

.indicators-images .item img {
    min-width: 43px;
    min-height: 43px;
}

.btn-shopping-cart-header {
    margin: 0 !important;
    color: #009688 !important;
    background-color: transparent !important;
    line-height: 64px !important;
    height: 64px !important;
}

.btn-shopping-cart-header i {
    line-height: 64px;
    height: 64px;
    width: 56px;
    text-align: center;
}

.btn-shopping-cart-header .notification-badge {
    background-color: #03a9f4 !important;
    box-shadow: 0 0 10px 0 #03a9f4 !important;
    position: relative;
    top: -32px;
    right: 11px;
    margin: 0 -.8em;
    padding: 2px 5px;
    color: #fff;
    border-radius: 50%;
    font-family: Muli,sans-serif;
}

.icon-cat {
    color: rgba(0,0,0,.87);
}

.sidenav.right-aligned {
    top: 0;
    width: 340px;
    padding-bottom: 0;
}


@media only screen and (min-width: 601px) {
    .img-container {
        height: calc(100vw * 0.239);
    }
    .img-container img {
        max-height: calc(100vw * 0.239);
    }
}

@media only screen and (min-width: 993px) {
    .img-container {
        height: calc(100vw * 0.179);
    }
    .img-container img {
        max-height: calc(100vw * 0.179);
    }
    .indicators-images .item img {
        min-width: 49px;
        min-height: 49px;
    } 
    .indicators-images .item {
        margin-right: 12px;
        padding: 2px;
    }    
}

@media only screen and (min-width: 1201px) {
    .img-container {
        height: calc(100vw * 0.143);
    }
    .img-container img {
        max-height: calc(100vw * 0.143);
    }
    .indicators-images .item img {
        min-width: 68px;
        min-height: 68px;
    }
    .indicators-images .item {
        margin-right: 13px;
        padding: 0;
    }
}

.flex {
    display: flex;
    align-items: center;
}

.b-5 {
    bottom: 5px;
}

.titulo-categorias, .titulo-pedido {
    color: #3f51b5;
    font-weight: 400;
}

hr {
    opacity: .3;
}

.fs-36 {
    font-size: 36px !important;
}

.l-260 {
    left: 260px !important
}

.r-340 {
    right: 340px !important
}

.logout-header {
    position: absolute;
    right: 6px;
    bottom: 6px;
}

@media only screen and (min-width: 993px) {
    .logout-header {
        right: 10px;
        bottom: 12px;
    }   
}

.logout-header a i {
    font-size: 1.6rem !important;
    line-height: 40px !important;
}

.logout-header a {
    cursor: pointer;
}

.switch label .lever {
    margin: 0 8px !important;
}

.switch-small-text {
    position: fixed;
    width: 75px;
    right: 15px;
    text-align: right;
}

.switch-login {
    position: relative;
    top: 1rem
}

.height-64 {
    height: 64px;
}

.height-56 {
    height: 56px;
}

#slide-out-pedido {
    height: 100% !important;
    margin: 0 auto -20px;
    min-height: 100%;
    padding: 0 0 20px;
    overflow-y: auto !important;
}

#slide-out-pedido li div.last {
    position: absolute;
    bottom: 5px;
}

#slide-out-pedido li div.left {
    left: 5px;
}

#slide-out-pedido li div.right {
    right: 5px;
}

.detalle .badge {
    margin-left: 0 !important;
}

.p-1 {
    padding: 1%;
}

.p-2 {
    padding: 2%;
}

.p-3 {
    padding: 3%;
}

.p-4 {
    padding: 4%;
}

.p-5 {
    padding: 5%;
}

span.checkbox::after {
    top: 2px !important;
}

.section-catalogo-productos .users-list-table .dataTable tbody td {
    padding: 0 !important;
}

.input-field.inline {
    width: 80%;
}

[type="checkbox"].filled-in-cat:checked+span:not(.lever):after {
    border: 2px solid #3f51b5 !important;
    background-color: #3f51b5 !important;
}

.ord-1 {
    order: 1;
}

.ord-2 {
    order: 2;
}

.flex-end {
    align-items: flex-end !important;
}




